/**
 * @param initialClass
 * @param additionalClasses
 * @returns {string}
 */
export function cssClass(initialClass, additionalClasses = [])
{
    return initialClass + ' ' + processAdditionalClasses(additionalClasses, initialClass);
}

/**
 * @param url
 */
export function clearStorageAndRedirect(url = '/')
{
    localStorage.clear();
    window.location.replace(url);
}

/**
 * @param data
 * @returns {string}
 */
export function makeQueryString(data)
{
    const ret = [];
    for (let d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
}

/**
 * @param additionalClasses
 * @param initialClass
 * @returns {string}
 */
function processAdditionalClasses(additionalClasses, initialClass)
{
    let classes = '';
    for (let i = 0; i < additionalClasses.length; i++) {
        classes = classes + initialClass + '_' + additionalClasses[i] + ' ';
    }

    return classes;
}
