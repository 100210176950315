import React from "react";
import {TableHeaderTr} from "./TableHeaderTr";

export const TableHeader = (props) => {
    return (
        <div className="table__thead">
            {
                props.thead.map(function (tr, index) {
                    return <TableHeaderTr tr={tr} key={index} order={props.order} handleSort={props.handleSort}/>
                })
            }
        </div>
    );
};