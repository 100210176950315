import React from "react";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const Pagination = (props) => {
    const currentPage = props.pagination.currentPage;
    const links       = props.pagination.links;
    const lastPage    = props.pagination.lastPage;
    const nextPage    = currentPage + 1;
    const prevPage    = currentPage - 1;

    const pagination = [];
    if (typeof links != 'undefined') {
        Object.values(links).map(function (element, index) {
            return pagination.push(<Pages element={element} current={currentPage} handlePage={props.handlePage} key={index}/>)
        })
    }

    return (
        <div className="pagi">
            <div className="pagi__item pagi__item_prev">
                {
                    currentPage > 1 && prevPage && <a className="pagi__link" href='#' onClick={(e) => props.handlePage(prevPage)}></a>
                }
            </div>

            {pagination}

            <div className="pagi__item pagi__item_next">
                {
                    currentPage < lastPage && lastPage && <a className="pagi__link" href='#' onClick={(e) => props.handlePage(nextPage)}></a>
                }
            </div>
        </div>
    );
};

/**
 * @param element
 * @param current
 * @param pageIndex
 * @returns {JSX.Element}
 * @constructor
 */
function Pages({element, current, handlePage})
{
    if (element === '...') {
        return <div className="pagi__item"> ... </div>
    }

    const pagination = [];
    for (const [index, item] of Object.entries(element)) {
        if (current == index) {
            pagination.push((<PageCurrent item={item} index={index} handlePage={handlePage} key={index}/>));
        } else {
            pagination.push((<PageNumber item={item} index={index} handlePage={handlePage} key={index}/>));
        }
    }

    return pagination;
}

/**
 * @param item
 * @param index
 * @param setCurrentPage
 * @returns {JSX.Element}
 * @constructor
 */
function PageNumber({item, index, handlePage})
{
    return (<div className="pagi__item"> <a className="pagi__link" href='#' onClick={(e) => handlePage(index)} key={index}>{index}</a></div>);
}

/**
 * @param item
 * @param index
 * @param setCurrentPage
 * @returns {JSX.Element}
 * @constructor
 */
function PageCurrent({item, index, handlePage})
{
    return <div className="pagi__item pagi__item_active" onClick={(e) => handlePage(index)} key={index}> <div className='pagi__link'>{index}</div> </div>;
}