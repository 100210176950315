import React from "react";
import {TableHeaderTotal} from "./TableHeaderTotal";
import {cssClass} from "../../../Helper";
import {TableHeader} from "./TableHeader";
import {TableBody} from "./TableBody";
import {Pagination as PaginationComponent} from "../Layout/Pagination";

export const Table = (props) => {
    if (props.hasTotal) {
        <TableHeaderTotal />
    }

    return (
        <>
            {props.tableActions()}

            <div className="table-responsive">
                <div className={cssClass('table', props.classes)}>
                    <TableHeader thead={props.thead} order={props.order} setOrder={props.setOrder} handleSort={props.handleSort} />
                    <TableBody tbody={props.tbody} rowActions={props.rowActions}/>
                </div>
            </div>
            {props.pagination.lastPage > 1 && <Pagination pagination={props.pagination} handlePage={props.handlePage}/>}
        </>
    );
};

/**
 *
 * @param pagination
 * @param setCurrentPage
 * @returns {JSX.Element|string}
 * @constructor
 */
function Pagination({pagination, handlePage})
{
    if (pagination) {
        return <PaginationComponent pagination={pagination} handlePage={handlePage}/>
    }

    return '';
}