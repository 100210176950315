import React from "react";
import {cssClass} from "../../../Helper";

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const TableBodyTr = (props) => {
    return (
        <Row tr={props.tr} id={props.id} rowActions={props.rowActions}/>
    );
};

/**
 * @param tr
 * @param id
 * @param actions
 * @returns {JSX.Element}
 * @constructor
 */
function Row({tr, id, rowActions}) {
    let row = tr && Object.values(tr).map(function(td, index) {
        if (id != td) {
            return <div className={cssClass('table__td', /*td.classes || []*/)} key={index}>{td}</div>;
        }
    })

    const index = row.length + 1;
    const actionComponents = rowActions(id, index);

    if (actionComponents.length) {
        actionComponents.map(function(component) {
            return row.push(component)
        })
    }

    if (tr.link) {
        return (
            <a className={cssClass('table__tr', /*tr.classes || []*/)} href={tr.link}>
                {row}
            </a>
        )
    }

    return (
        <div className={cssClass('table__tr', /*tr.classes || []*/)}>
            {row}
        </div>
    );
}