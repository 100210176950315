import './App.css';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./AuthConfig";
import ReactDOM from 'react-dom/client';
import React from 'react';
import App from './App';
import {HelmetProvider } from 'react-helmet-async';


const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <HelmetProvider>
                <App />
            </HelmetProvider>
        </MsalProvider>
    </React.StrictMode>
);
