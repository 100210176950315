import React, {useEffect, useRef, useState} from "react";

export const Multiple = (props) => {
    const toggleDivFilter       = useRef(null);
    const searchInputFilter     = useRef(null);
    const searchFilterDiv       = useRef(null);
    const searchFilterTitleDiv  = useRef(null);
    const [options, setOptions] = useState(props.filter.options);

    const handFilter = (e) => {
        props.handleFilter(e);

        const inputs = document.querySelectorAll( '.' + toggleDivFilter.current.classList[1] + " input[type='checkbox']");
        let title = [];
        inputs.forEach(function(input, value, index) {
            if (input.checked) {
                title.push(input.dataset.label);
            }
        })

        if (title.length) {
            searchFilterTitleDiv.current.innerHTML = title.join(', ');
            toggleDivFilter.current.classList.add('search-filter_selected');
        } else {
            hideAndClearFilterTitle();
        }
    }

    useEffect(() => {
        let openedFilter = localStorage.getItem("openedFilter");
        if (openedFilter !== null) {
            toggleDivFilter.current.querySelector("input[type='checkbox']")

            const filter = document.querySelector( "div[data-filter='" + openedFilter + "']");
            if (!filter.classList.contains('search-filter_expanded')) {
                filter.classList.add('search-filter_expanded')
            }
        }

    }, [props.filterParams]);

    const hideAndClearFilterTitle = (e) => {

        toggleDivFilter.current.classList.remove('search-filter_expanded');
        toggleDivFilter.current.classList.remove('search-filter_selected');
        searchFilterTitleDiv.current.innerHTML = searchFilterTitleDiv.current.dataset.defaultText;
    }

    // Open / Close filters
    const toggleFilter = (e) => {
        localStorage.removeItem("openedFilter")

        const filters = document.getElementsByClassName('search-filter_expanded');
        for (let filter of filters) {
            if (filter != toggleDivFilter.current) {
                filter.classList.remove('search-filter_expanded');
            }
        }

        const hasClass = toggleDivFilter.current.classList.contains('search-filter_expanded');
        if (!hasClass) {
            toggleDivFilter.current.classList.add('search-filter_expanded')
            localStorage.setItem("openedFilter", toggleDivFilter.current.dataset.filter)
        } else {
            toggleDivFilter.current.classList.remove('search-filter_expanded')
            localStorage.removeItem("openedFilter")
        }
    };

    const closeFilterOnAnyClick = (e) => {
        props.handleFilter(e);

        const inputs = toggleDivFilter.current.querySelectorAll("input[type='checkbox']");
        Array.prototype.every.call(inputs, function(input) {
            input.checked = false;
            return input;
        });

        hideAndClearFilterTitle();
        localStorage.removeItem("openedFilter")
    }

    const searchFilterValues = (e) => {
        searchFilterDiv.current.classList.remove('form__group_not-empty');

        const search = e.target.value.toLowerCase();
        if (search) {
            searchFilterDiv.current.classList.add('form__group_not-empty');
        }

        const asArray = Object.entries(props.filter.options);
        var filtered = asArray.filter(function (element, index) {
            const toLower = element[1].toLowerCase();
            if (toLower.startsWith(search) !== false) {
                return element;
            }
        })
        setOptions(Object.fromEntries(filtered));
    }

    const clearSearchFilterValuesInput = (e) => {
        searchFilterDiv.current.classList.remove('form__group_not-empty');
        searchInputFilter.current.value = '';
        setOptions(props.filter.options);
    }

    useEffect(() => {
        const filter = props.filter.filterKey;

        let filterParams = [];
        props.filterParams.forEach(function(param, index) {
            if (Object.keys(param)[0] === filter) {
                filterParams = param[filter];
                return;
            }
        });

        const inputs = document.querySelectorAll( "input[data-filter='" + props.filter.filterKey + "']");
        Array.prototype.every.call(inputs, function(input) {
            if (filterParams.includes(input.value) != false) {
                input.checked = true;

                return input;
            }

            return input;
        });
    }, [options]);

    let title = <Title filter={props.filter} toggleFilter={toggleFilter} searchFilterTitleDiv={searchFilterTitleDiv} filterParams={props.filterParams} options={options}/>

    let activeFilter = false;
    props.filterParams.forEach(function(param, index) {
        if (Object.keys(param)[0] === props.filter.filterKey && Object.values(param)[0].length) {
            activeFilter = true;
        }
    });

    return (
        <>
            <div className={activeFilter ? 'search-filter search-filter_selected' : 'search-filter'} ref={toggleDivFilter} data-filter={props.filter.filterKey}>
                {title}

                <div className="search-filter__dropdown">
                    <div className="search-filter__top">
                        <div className="form_find">
                            <div className="form__group form__group_clear" ref={searchFilterDiv}>
                                <input className="form__control" type="text" onChange={searchFilterValues} ref={searchInputFilter} name={props.filter.emptyValue ? props.filter.emptyValue : 'ac_category'} />
                                <a className="form__clear" onClick={clearSearchFilterValuesInput}></a>
                            </div>
                        </div>
                    </div>
                    <div className="search-filter__content search-filter__content_checkboxes">
                        <Options options={options} name={props.filter.name} filterKey={props.filter.filterKey} handleFilter={handFilter}/>
                    </div>
                    <div className="search-filter__bottom">
                        <a className="search-filter__any" href='#' data-filter={props.filter.filterKey}  onClick={closeFilterOnAnyClick}>{props.filter.anyTitle}</a>
                    </div>
                </div>
            </div>
        </>
    );
};

function Options({options, name, filterKey, handleFilter})
{
    let values = [];

    for (const [value, label] of Object.entries(options)) {
        values.push(<Option filterKey={filterKey} name={name} value={value} label={label} key={value} handleFilter={handleFilter}/>)
    }

    return values;
}

function Option({filterKey, name, value, label, handleFilter})
{
    return (<label className="search-filter__label"><input data-filter={filterKey} name={name} value={value} type="checkbox" data-label={label} onClick={handleFilter} /><span>{label}</span></label>);
}

function Title({filter, toggleFilter, searchFilterTitleDiv, filterParams, options})
{
    let title        = filter.title;
    let defaultTitle = filter.title;

    // Set actual filter title
    filterParams.forEach(function(param, index) {
        if (Object.keys(param)[0] === filter.filterKey && Object.values(param)[0].length) {
            title = [];

            Object.values(param)[0].map(function(value) {
                title.push(options[value]);
            })

            title = title.join(', ');
        }
    });

    return <a className="search-filter__value" data-default-text={defaultTitle} title={title} onClick={toggleFilter} ref={searchFilterTitleDiv}>{title}</a>;
}
