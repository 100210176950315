import React, {useEffect, useState} from "react";
import {PageLayout} from "./components/PageLayout";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import {SignInForm} from "./components/SignInForm";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {loginRequest} from "./AuthConfig";
import { Helmet } from 'react-helmet-async';

function App() {
    const [isTokenValidated, setIsTokenValidated] = useState(localStorage.getItem("isTokenValidated") || false);
    const [authToken, setAuthTokens]              = useState(localStorage.getItem("token") || "");
    const [errorMessage, setErrorMessage]         = useState('');

    const setToken = (data) => {
        localStorage.setItem("token", data);
        setAuthTokens(data);
    };

    const isAuthenticated        = useIsAuthenticated();
    const { instance, accounts } = useMsal();

    if (isAuthenticated) {
        const request = {...loginRequest, account: accounts[0]};

        instance.acquireTokenSilent(request).then((response) => {
            setToken(response.accessToken);
        }).catch((e) => {});
    }

    useEffect(() => {
        if (isAuthenticated) {
            fetch(process.env.REACT_APP_API_URL + "validate-token", {method: "POST", headers: {'Authorization': 'Bearer ' + authToken}})
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.hasOwnProperty('error')) {
                            setErrorMessage(result.message)
                            localStorage.clear();
                        } else {
                            setIsTokenValidated(true);
                            localStorage.setItem("isTokenValidated", true)
                        }
                    }
                )
        }
    }, [authToken])

    return (
        <>
            <AuthenticatedTemplate>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Zorka Clients</title>
                </Helmet>
                { isTokenValidated && <PageLayout token={authToken} />}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <SignInForm errorMessage={errorMessage}/>
            </UnauthenticatedTemplate>
        </>
    );
}

export default App;