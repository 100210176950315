import React, {useState, useEffect, useRef} from "react";
import {Table} from "./Shared/Table/Table";
import {Search} from "./Shared/Search/Search";
import {makeQueryString, clearStorageAndRedirect} from "../Helper";

export const Dashboard = (props) => {
    const apiUrl                        = process.env.REACT_APP_API_URL;
    const searchInput                   = useRef(null);
    const [items, setItems]             = useState([]);
    const [pagination, setPagination]   = useState([]);
    const [currentPage, setCurrentPage] = useState(defineDefaultPageParams());
    const [search, setSearch]           = useState(defineDefaultSearchParams());
    const [filters, setFilters]         = useState([]);

    const defaultSort       = {sort: 'name', order: 'asc'};
    const [order, setOrder] = useState(defineDefaultSortParams(defaultSort));

    const defaultFilterParams = [
        {services : []}, {managers: []}, {leads: []}, {categories: []}, {verticals: []}, {geos: []}, {sources: []}
    ];

    const [filterParams, setFilterParams] = useState(defineDefaultFilterParams(defaultFilterParams));

    const tableClasses = ['width_100', 'align_center', 'hover'];

    const thead = [
        {
            'data' : [
                {title: 'ID', field: 'id', order: false},
                {title: 'Name', field: 'name', order: true},
                {title: 'Service', field: 'services', order: true},
                {title: 'Sales Manager', field: 'managers', order: true},
                {title: 'Account Manager', field: 'leads', order: true},
                {title:'HubSpot', field: 'hubspot', order: false},
                {title:'', field: '', order: false}
            ]
        }
    ];

    /**
     *
     * @param defaultSort
     * @returns {any}
     */
    function defineDefaultSortParams(defaultSort)
    {
        let savedSortParams = localStorage.getItem("sortParams");

        if (!savedSortParams) {
            savedSortParams = defaultSort;
            localStorage.setItem("sortParams", JSON.stringify(savedSortParams));
        } else {
            savedSortParams = JSON.parse(savedSortParams);
        }

        return savedSortParams;
    }

    function defineDefaultSearchParams()
    {
        let savedSearchParams = localStorage.getItem("searchParams");

        if (!savedSearchParams) {
            savedSearchParams = '';
        }

        return savedSearchParams;
    }

    /**
     *
     * @param defaultFilterParams
     * @returns {any}
     */
    function defineDefaultFilterParams(defaultFilterParams)
    {
        let savedFilterParams = localStorage.getItem("filterParams");

        if (!savedFilterParams) {
            savedFilterParams = defaultFilterParams;
        } else {
            savedFilterParams = JSON.parse(savedFilterParams);
        }

        return savedFilterParams;
    }

    /**
     *
     * @returns {any}
     */
    function defineDefaultPageParams()
    {
        let savedPageParams = localStorage.getItem("pageParams");
        if (!savedPageParams) {
            savedPageParams = 1;
        }

        return savedPageParams;
    }

    /**
     * @param filterValues
     */
    function setDefaultFilters(filterValues)
    {
        setFilters({
                services: {filterKey: 'services', name: 'services[]', title: 'Service', anyTitle: 'Any service', options: filterValues.services, value: [], type: 'multiple'},
                managers: {filterKey: 'managers', name: 'managers[]', title: 'Sales Manager', anyTitle: 'Any sales manager', options: filterValues.managers, value: [], type: 'multiple'},
                leads: {filterKey: 'leads', name: 'leads[]', title: 'Account Manager', anyTitle: 'Any account manager', options: filterValues.leads, value: [], type: 'multiple'},
                categories: {filterKey: 'categories', name: 'categories[]', title: 'Category', anyTitle: 'Any category', options: filterValues.categories, value: [], type: 'multiple'},
                verticals: {filterKey: 'verticals', name: 'verticals[]', title: 'Vertical', anyTitle: 'Any vertical', options: filterValues.verticals, value: [], type: 'multiple'},
                geos: {filterKey: 'geos', name: 'geos[]', title: 'GEO', anyTitle: 'Any geo', options: filterValues.geos, value: [], type: 'multiple'},
                sources: {filterKey: 'sources', name: 'sources[]', title: 'Lead Source', anyTitle: 'Any lead source', options: filterValues.sources, value: [], type: 'multiple'},
            }
        );
    }

    function exportClients()
    {
        let params = {page: currentPage, search: search, filterParams: JSON.stringify(filterParams), order: JSON.stringify(order)}

        fetch(apiUrl + "api/clients/export?" + makeQueryString(params), {headers: {'Authorization': 'Bearer ' + props.token}})
            .then(
                res =>  {
                    if (res.status === 403) {
                        return Promise.reject(res);
                    } else {
                        return res.blob();
                    }
                }
            )
            .then(
                (result) => {
                    const fileURL = window.URL.createObjectURL(result);
                    let alink      = document.createElement('a');
                    alink.href     = fileURL;
                    alink.download = 'client-export.xlsx';
                    alink.click();
                }
            ).catch(error => {
                clearStorageAndRedirect();
        })
    }

    /**
     * @param e
     */
    function handleSearch(e)
    {
        e.preventDefault();
        setSearch(searchInput.current.value);
        setCurrentPage(1);
        localStorage.setItem("searchParams", searchInput.current.value);
    }

    /**
     * @param page
     */
    function handlePage(page)
    {
        setCurrentPage(page);
        localStorage.setItem("pageParams", page);
    }

    function handleSort(e)
    {
        const sort    = e.target.dataset.sort;
        const sortObj = {sort: sort, order: order.order === 'asc' ? 'desc' : 'asc'};

        setOrder(sortObj);
        setCurrentPage(1);

        localStorage.setItem("sortParams", JSON.stringify(sortObj));
    }

    function handleFilter(e)
    {
        const filter  = e.target.dataset.filter;
        const value   = e.target.value;
        const checked = e.target.checked;

        const refreshFilterParams = filterParams.map(function(param, key) {
            if (Object.keys(param)[0] == filter) {
                if (value === undefined) {
                    return {[filter]: []};
                }

                if (checked) {
                    return  {...param, [filter]: [...param[filter], value]};

                } else {

                    const index = param[filter].indexOf(value);
                    param[filter].splice(index, 1);

                    return {[filter]: param[filter]};
                }
            }

            return param;
        });

        localStorage.setItem("filterParams", JSON.stringify(refreshFilterParams));

        setFilterParams(refreshFilterParams);
        setCurrentPage(1);
    }

    useEffect(() => {
        let params = {page: currentPage, search: search, filterParams: JSON.stringify(filterParams), order: JSON.stringify(order)};

        fetch(apiUrl + "api/clients?" + makeQueryString(params), {headers: {'Authorization': 'Bearer ' + props.token}})
            .then(
                res =>  {
                    if (res.status === 403) {
                        return Promise.reject(res);
                    } else {
                        return res.json();
                    }
                }
            )
            .then(
                (result) => {
                    setItems(JSON.parse(result.data));
                    setPagination(result.pagination);
                    setDefaultFilters(result.filterValues)
                }
            ).catch( (res) => {
                clearStorageAndRedirect();
        } );
    }, [currentPage, search, filterParams, order])

    function tableActions()
    {
        return <div className='main-header'>
            <div className='main-header__right'>
                <div className='main-header main-header_acts'>
                    <div className='acts'>
                        <a className='act act_add' href='/clients/create' title='Add Client'></a>
                        <a className='act act_sheet' onClick={exportClients} href='#' title='Export'></a>
                    </div>
                </div>
            </div>
        </div>
    }

    function rowActions(id, index)
    {
        return [<RowEditAction id={id} key={index}/>]
    }

    function RowEditAction({id})
    {
        return <div className='table__td'>
            <div className={'main-header main-header_acts'}>
                <div className='main-header__right'>
                    <div className='acts'>
                        <a className='act act_edit' data-tooltip='Edit' href={'/clients/' + id + '/edit'}> </a>
                    </div>
                </div>
            </div>
        </div>;
    }

    return (
        <>
            <Search
                filterParams={filterParams}
                defaultFilterParams={defaultFilterParams}
                filters={filters}
                search={search}
                defaultSort={defaultSort}
                handlePage={handlePage}
                searchInput={searchInput}
                setSearch={setSearch}
                setFilterParams={setFilterParams}
                setOrder={setOrder}
                handleSearch={handleSearch}
                handleFilter={handleFilter}
            />
            <div className='widget'>
                <Table
                    thead={thead}
                    tbody={items}
                    order={order}
                    pagination={pagination}
                    currentPage={currentPage}
                    handlePage={handlePage}
                    handleSort={handleSort}
                    classes={tableClasses}
                    rowActions={rowActions}
                    tableActions={tableActions}
                />
            </div>
        </>
    );
};



