import React from "react";
import {BrowserRouter as Router, Routes, Route, useMatch, useResolvedPath} from "react-router-dom";
import {Dashboard} from "../../Dashboard";
import {ClientsForm} from "../../ClientsForm";
import {Footer} from "./Footer";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const TopMenu = (props) => {
    const links = [{name: 'Dashboard', link: '/'}];

    function signOutClickHandler() {
        localStorage.clear();
        window.location.replace('/');
    }

    function SignOutButton() {
        return (
            <div className='main-top__icons'>
                <a href='#' onClick={() => signOutClickHandler()}>Sing Out</a>
            </div>
        );
    }

    return (
        <>
            <Router>
                <div className="main-top" id="main-top">
                    <div className="main-top__inner">
                        <a className="logo" href="">Core</a>
                        <div className="menu">
                            <div className="menu__items">
                                {
                                    links.map(function (menu, index) {
                                        return <CustomLink to={menu.link} name={menu.name} key={index}/>
                                    })
                                }
                            </div>
                        </div>
                        <SignOutButton />
                    </div>
                </div>

                <div className="main-container">
                    <div className="main-content">
                        <div className="main-content__inner">
                            <Routes>
                                <Route index path="/" element={<Dashboard token={props.token} />} />
                                <Route index path="/clients/:clientId/edit" element={<ClientsForm token={props.token} />} />
                                <Route index path="/clients/create" element={<ClientsForm token={props.token} />} />
                            </Routes>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </Router>
        </>
    );
};

function CustomLink({to, name}) {
    let resolved = useResolvedPath(to);
    let match    = useMatch({ path: resolved.pathname, end: true });

    return (
        <div className={match ? "menu__item menu__item_active" : 'menu__item'}>
            <a className="menu__link " href={to}>{name}</a>
        </div>
    );
}
