import React from "react";
import {SearchFilter} from "./SearchFilter";

export const Search = (props) => {

    /**
     * @param e
     */
    function handleClear(e)
    {
        props.setSearch('');
        props.searchInput.current.value = '';
        props.handlePage(1);
        props.setOrder(props.defaultSort);

        localStorage.setItem("filterParams", JSON.stringify(props.defaultFilterParams));
        localStorage.setItem("searchParams", '');
        localStorage.setItem("pageParams", 1);
        localStorage.setItem("orderParams", JSON.stringify(props.defaultSort));
        localStorage.removeItem("openedFilter")

        resetFilterParams();
        setFilterToDefault();
    }

    function setFilterToDefault()
    {
        // Close all opened filters
        const filters = document.querySelectorAll( ".search-filter_expanded");
        Array.prototype.every.call(filters, function(input) {
            input.classList.remove('search-filter_expanded');

            return input;
        });

        // Set default title for all filters
        const filterTitles = document.querySelectorAll( ".search-filter__value");
        Array.prototype.every.call(filterTitles, function(input) {
            input.innerHTML = input.dataset.defaultText;

            return input;
        });

        // Set active filters to inactive
        const activeFilters = document.querySelectorAll( ".search-filter_selected");
        Array.prototype.every.call(activeFilters, function(input) {
            input.classList.remove('search-filter_selected');

            return input;
        });

        // Uncheck all filters
        const inputs = document.querySelectorAll( ".search-filter input[type='checkbox']");
        Array.prototype.every.call(inputs, function(input) {
            input.checked = false;

            return input;
        });
    }

    function resetFilterParams()
    {
        const resetFilterParams = props.filterParams.map(function(param, key) {
            const filter = Object.keys(param)[0];
            return {[filter]: []};
        })

        props.setFilterParams(resetFilterParams);
    }

    return (
        <>
            <form className="form form_search" onSubmit={props.handleSearch} noValidate>
                <div className="form_search__item-grow">
                    <div className="form__group">
                        <input type="search" ref={props.searchInput} defaultValue={props.search} name="name" className='form__control'/>
                    </div>
                    <div className="search-filters">
                        <SearchFilter filterParams={props.filterParams} filters={props.filters} handleFilter={props.handleFilter}/>
                        <div className="search-filter search-filter_clear">
                            <a className="search-filter__clear" href='#' onClick={handleClear}>Clear</a>
                        </div>
                    </div>
                </div>
                <div className="form_search__item-button">
                    <button className="btn btn_primary btn_width_m" type="submit" onClick={props.setSearch}>Search</button>
                </div>
            </form>
        </>
    );
};