import '../App.css';
import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../AuthConfig";

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInForm = (props) => {
  const { instance } = useMsal();

  const handleLogin = () => {
      instance.loginRedirect(loginRequest)
      .catch(e => {
        console.log(e);
      });
  }

  return (
    <div className="modal-container">
      <div className="modal" id="modal_signin" style={{display: 'block'}}>
        <div className="modal__wrapper">
          <div className="modal__content">
            <div className="modal__logo"></div>
            <div className="modal__header">
              ZORKA.PLATFORM
            </div>

            {props.errorMessage &&
              <div className='modal__error'><p>{props.errorMessage}</p></div>
            }

            <form method={'post'} className='form' id={'form_signin'}>
              <div className="buttons-bottom">
                <a className="btn btn_primary btn_width_100" href="#" onClick={() => handleLogin()}><span>Sign in with Microsoft</span></a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}