import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Box} from "@mui/material";
import moment from 'moment';
import {clearStorageAndRedirect} from "../Helper";

export const ClientsForm = (props) => {
    const apiUrl                     = process.env.REACT_APP_API_URL;
    const [data, setData]            = useState(null);
    const [isLoaded, setIsLoaded]    = useState(false);
    const [date, setDate]            = useState(null);

    const {clientId}                           = useParams();
    const { setValue, register, handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        clearErrorViews();

        const method = e.target.getAttribute('method');
        let url = apiUrl + 'api/clients';

        if (method === 'PUT') {
            url = apiUrl + 'api/clients/' + clientId
        }

        fetch(url, {method: method, headers: {'Authorization': 'Bearer ' + props.token, 'Content-Type': 'application/json', 'Accept': 'application/json'}, body: JSON.stringify(data)})
            .then(res => {
                if (res.status === 403) {
                    return Promise.reject(res);
                } else {
                    return res.json();
                }
            })
            .then(
                (result) => {

                    if (result.hasOwnProperty('errors')) {
                        const errors = result.errors;
                        Object.entries(errors).map(function(error, field) {
                            let input = document.getElementsByName(error[0])[0];
                            let errorDiv = input.parentNode.querySelector('.form__error');

                            let errorMessages = [];
                            Object.values(error[1]).map(function(error, field) {
                                errorMessages.push(error);
                            })

                            errorDiv.innerHTML = errorMessages.join(', ');
                            let formGroup = input.closest('.form__group');
                            formGroup.classList.add('form__group_error')

                            if (field === 0) {
                                input.scrollIntoView({ behavior: 'smooth', block: 'end' });
                            }
                        })
                    } else {
                        window.location.replace('/');
                    }
                },
            ).catch(error => {
                clearStorageAndRedirect();
        })

    };
    const onError = (errors, e) => console.log(errors, e);

    function clearErrorViews()
    {
        const formGroupErrors = document.getElementsByClassName('form__group_error');
        for (let formGroupError of formGroupErrors) {
            formGroupError.classList.remove('form__group_error');
        }

        const formErrors = document.getElementsByClassName('form__error');
        for (let formError of formErrors) {
            formError.innerHTML = '';
        }
    }

    if (clientId) {
        return <Edit
            apiUrl={apiUrl}
            id={clientId}
            token={props.token}
            data={data}
            setData={setData}
            isLoaded={isLoaded}
            setIsLoaded={setIsLoaded}
            register={register}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            onError={onError}
            setValue={setValue}
            date={date}
            setDate={setDate}
        />
    }

    return <Create
        apiUrl={apiUrl}
        token={props.token}
        data={data}
        setData={setData}
        isLoaded={isLoaded}
        setIsLoaded={setIsLoaded}
        register={register}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        onError={onError}
        setValue={setValue}
        date={date}
        setDate={setDate}
    />;
};

function Create({apiUrl, token, data, setData, isLoaded, setIsLoaded, register, handleSubmit, onSubmit, onError, setValue, date, setDate})
{
    useEffect(() => {
        fetch(apiUrl + "api/clients/create", {headers: {'Authorization': 'Bearer ' + token}})
            .then(res => {
                if (res.status === 403) {
                    return Promise.reject(res);
                } else {
                    return res.json();
                }
            })
            .then(
                (result) => {
                    setData(result)
                    setIsLoaded(true)
                },
            ).catch(error => {
                clearStorageAndRedirect();
        })
    }, [])


    if (isLoaded) {
        return <>
            <MainHeader title='Create Client'/>
            <EmptyForm
                action={apiUrl + 'api/clients'}
                method='POST'
                data={data}
                register={register}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                onError={onError}
                setValue={setValue}
                date={date}
                setDate={setDate}
            />
        </>
    }
}

function Edit({apiUrl, id, token, data, setData, isLoaded, setIsLoaded, register, handleSubmit, onSubmit, onError, setValue, date, setDate})
{
    useEffect(() => {
        fetch(apiUrl + "api/clients/" + id + '/edit', {headers: {'Authorization': 'Bearer ' + token}})
            .then(res => {
                if (res.status === 403) {
                    return Promise.reject(res);
                } else {
                    return res.json();
                }
            })
            .then(
                (result) => {
                    setData(result)
                    setIsLoaded(true)
                    setDate(result.client.start_date)
                },
            ).catch(error => {
                clearStorageAndRedirect();
        })
    }, [])

    if (isLoaded) {
        return <>
            <MainHeader title='Edit Client'/>
            <Form
                action={apiUrl + 'api/clients/' + id}
                method='PUT'
                data={data}
                register={register}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                onError={onError}
                setValue={setValue}
                date={date}
                setDate={setDate}
            />
        </>
    }
}

function MainHeader({title})
{
    return <div className='main-header'>
        <div className='main-header__left'>
            <a className='btn btn_back-gray' href='/'></a>
        </div>
        <div className='main-header__content'>
            <div className='header'>{title}</div>
        </div>
    </div>
}

function Form({action, method, data, register, handleSubmit, onSubmit, onError, setValue, date, setDate})
{
    const externalId = data.client.external_id ? data.client.external_id : data.client.hubspot_deal_id;

    let readonly  = false;
    if (data.client.is_hubspot || data.client.disabled) {
        readonly= true;
    }

    return <form className='form form_vertical' method={method} action={action} onSubmit={handleSubmit(onSubmit, onError)}>
        <div className='section-form'>
            <div className='section-form__content'>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>External Id</label>
                    </div>
                    <input name='external_id' {...register("external_id")} defaultValue={externalId} className='form__control' type='text' />
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Name</label>
                    </div>
                    <input name='name' {...register("name")} defaultValue={data.client.name} className='form__control' type='text' disabled={readonly} />
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Legal Name</label>
                    </div>
                    <input name='legal_name' {...register("legal_name")} defaultValue={data.client.legal_name} className='form__control' type='text' disabled={readonly} />
                    <div className='form__error'></div>
                </div>

                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Start Date</label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disabled={readonly}
                            value={date}
                            inputFormat="LL"
                            disableMaskedInput={true}
                            onChange={(newValue) => {
                                setDate(newValue)
                                setValue('start_date', moment(newValue.$d).format('YYYY-MM-DD'))
                            }}
                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <input ref={inputRef} {...inputProps} name='start_date' className='form__control' placeholder='' type='text'/>
                                    {InputProps?.endAdornment}
                                </Box>
                            )}

                        />
                    </LocalizationProvider>
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Project</label>
                    </div>
                    <input name='project' {...register("project")} className='form__control' defaultValue={data.client.project} type='text' disabled={readonly} />
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Sales Manager</label>
                    </div>
                    <select name="manager" {...register("manager")} className='form__control' defaultValue={data.client.sales_manager_id} disabled={readonly} >
                        <option value="" key={0}>--Please choose Sales Manager--</option>
                        {Object.entries(data.managers).map(function (element, index) {
                            return <option key={element[0]} value={element[0]}>{element[1]}</option>
                        })}
                    </select>
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Account Manager</label>
                    </div>
                    <select name="lead" {...register("lead")} className='form__control' defaultValue={data.client.account_manager_id} disabled={readonly} >
                        <option value="" key={0}>--Please choose Project Lead--</option>
                        {Object.entries(data.leads).map(function (element, index) {
                            return <option key={element[0]} value={element[0]}>{element[1]}</option>
                        })}
                    </select>
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Category</label>
                    </div>
                    <select name="category" {...register("category")} className='form__control' defaultValue={data.client.client_category_id} disabled={readonly} >
                        <option value="" key={0}>--Please choose Category--</option>
                        {Object.entries(data.categories).map(function (element, index) {
                            return <option key={element[0]} value={element[0]}>{element[1]}</option>
                        })}
                    </select>
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Service</label>
                    </div>
                    <select name="service" {...register("service")} className='form__control' defaultValue={data.client.service_to_provide_id} disabled={readonly} >
                        <option value="" key={0}>--Please choose Service--</option>
                        {Object.entries(data.services).map(function (element, index) {
                            return <option value={element[0]} key={element[0]}>{element[1]}</option>
                        })}
                    </select>
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Vertical</label>
                    </div>
                    <select name="vertical" {...register("vertical")} className='form__control' defaultValue={data.client.vertical_id} disabled={readonly} >
                        <option value="" key={0}>--Please choose Vertical--</option>
                        {Object.entries(data.verticals).map(function (element, index) {
                            return <option value={element[0]} key={element[0]}>{element[1]}</option>
                        })}
                    </select>
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Geo</label>
                    </div>
                    <select name="geo" {...register("geo")}  className='form__control' defaultValue={data.client.geo_id} disabled={readonly} >
                        <option value="" key={0}>--Please choose Geo--</option>
                        {Object.entries(data.geos).map(function (element, index) {
                            return <option value={element[0]} key={element[0]}>{element[1]}</option>
                        })}
                    </select>
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Lead Sources</label>
                    </div>
                    <select name="source" {...register("source")} className='form__control' defaultValue={data.client.lead_source_id} disabled={readonly} >
                        <option value="" key={0}>--Please choose Lead Sources--</option>
                        {Object.entries(data.sources).map(function (element, index) {
                            return <option value={element[0]} key={element[0]}>{element[1]}</option>
                        })}
                    </select>
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <label className='form__label-checkbox'>
                        <input name='disabled' {...register("disabled")} type='checkbox' disabled={readonly} defaultChecked={data.client.disabled} className='form__control' readOnly={readonly} />
                        <span>Disabled</span>
                    </label>
                    <div className='form__error'></div>
                </div>
            </div>
        </div>

        <div className='buttons-bottom'>
            <input className='btn btn_primary btn_width_l' type='submit' value='Save'/>
        </div>
    </form>
}

function EmptyForm({action, method, data, register, handleSubmit, onSubmit, onError, setValue, date, setDate})
{
    return <form className='form form_vertical' method={method} action={action} onSubmit={handleSubmit(onSubmit, onError)}>
        <div className='section-form'>
            <div className='section-form__content'>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>External Id</label>
                    </div>
                    <input name='external_id' {...register("external_id")} className='form__control' type='text' />
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Name</label>
                    </div>
                    <input name='name' {...register("name")} className='form__control' type='text' />
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Legal Name</label>
                    </div>
                    <input name='legal_name' {...register("legal_name")} className='form__control' type='text' />
                    <div className='form__error'></div>
                </div>

                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Start Date</label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={date}
                            inputFormat="LL"
                            disableMaskedInput={true}
                            onChange={(newValue) => {
                                setDate(newValue)
                                setValue('start_date', moment(newValue.$d).format('YYYY-MM-DD'))
                            }}
                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <input ref={inputRef} {...inputProps} name='start_date' className='form__control' placeholder='' type='text'/>
                                    {InputProps?.endAdornment}
                                </Box>
                            )}

                        />
                    </LocalizationProvider>

                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Project</label>
                    </div>
                    <input  name='project' {...register("project")} className='form__control' type='text'/>
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Sales Manager</label>
                    </div>
                    <select name="manager" {...register("manager")} className='form__control'>
                        <option value="" key={0}>--Please choose Sales Manager--</option>
                        {Object.entries(data.managers).map(function (element, index) {
                            return <option key={element[0]} value={element[0]}>{element[1]}</option>
                        })}
                    </select>
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Account Manager</label>
                    </div>
                    <select name="lead" {...register("lead")} className='form__control'>
                        <option value="" key={0}>--Please choose Account Manager--</option>
                        {Object.entries(data.leads).map(function (element, index) {
                            return <option key={element[0]} value={element[0]}>{element[1]}</option>
                        })}
                    </select>
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Category</label>
                    </div>
                    <select name="category" {...register("category")} className='form__control'>
                        <option value="" key={0}>--Please choose Category--</option>
                        {Object.entries(data.categories).map(function (element, index) {
                            return <option key={element[0]} value={element[0]}>{element[1]}</option>
                        })}
                    </select>
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Service</label>
                    </div>
                    <select name="service" {...register("service")} className='form__control'>
                        <option value="" key={0}>--Please choose Service--</option>
                        {Object.entries(data.services).map(function (element, index) {
                            return <option value={element[0]} key={element[0]}>{element[1]}</option>
                        })}
                    </select>
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Vertical</label>
                    </div>
                    <select name="vertical" {...register("vertical")} className='form__control'>
                        <option value="" key={0}>--Please choose Vertical--</option>
                        {Object.entries(data.verticals).map(function (element, index) {
                            return <option value={element[0]} key={element[0]}>{element[1]}</option>
                        })}
                    </select>
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Geo</label>
                    </div>
                    <select name="geo" {...register("geo")} className='form__control'>
                        <option value="" key={0}>--Please choose Geo--</option>
                        {Object.entries(data.geos).map(function (element, index) {
                            return <option value={element[0]} key={element[0]}>{element[1]}</option>
                        })}
                    </select>
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <div className='form__top'>
                        <label className='form__label'>Lead Sources</label>
                    </div>
                    <select name="source" {...register("source")} className='form__control'>
                        <option value="" key={0}>--Please choose Lead Sources--</option>
                        {Object.entries(data.sources).map(function (element, index) {
                            return <option value={element[0]} key={element[0]}>{element[1]}</option>
                        })}
                    </select>
                    <div className='form__error'></div>
                </div>
                <div className='form__group'>
                    <label className='form__label-checkbox'>
                        <input name='disabled' {...register("disabled")} type='checkbox' className='form__control'/>
                        <span>Disabled</span>
                    </label>
                    <div className='form__error'></div>
                </div>
            </div>
        </div>

        <div className='buttons-bottom'>
            <input className='btn btn_primary btn_width_l' type='submit' value='Save'/>
        </div>
    </form>
}
