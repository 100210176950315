import React from "react";
import {TopMenu} from "./Shared/Layout/TopMenu";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout = (props) => {
    return (
        <>
            <div className="app">
                <TopMenu token={props.token}/>
            </div>
        </>
    );
};