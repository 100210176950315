import React from "react";
import {TableBodyTr} from "./TableBodyTr";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const TableBody = (props) => {
    return (
        props.tbody.length
        ?
        <div className="table__tbody">
            {
                props.tbody && props.tbody.map(function (tr, index) {
                    return <TableBodyTr tr={tr} key={index} rowActions={props.rowActions} id={tr.id}/>;
                })
            }
        </div>
        :
        <div className="table__empty">
            There's nothing here, yet.
        </div>
    );
};