import React from "react";
import {cssClass} from "../../../Helper";

export const TableHeaderTh = (props) => {
    return (
        <div key={props.index} className={cssClass('table__th', props.th.classes)}  data-column-title={props.th.title}>
            {
                props.th.order
                ?
                    <SortArrow th={props.th} order={props.order} handleSort={props.handleSort} sort={props.th.field}/>
                :
                    props.th.title
            }
        </div>
    );
};

function SortArrow({th, order, handleSort, sort})
{
    if (th.field == order.sort) {
        return <a className={'table__sort table__sort_' + order.order} data-sort={sort} onClick={handleSort}> {th.title} </a>;
    }

    return (<a className='table__sort' onClick={handleSort} data-sort={sort}>{th.title}</a>);
}