import React from "react";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const Footer = (props) => {
    return (
        <div className="main-footer">
            <div className="inner">
                <div className="copy">
                    © <a href="#">Zorka.Agency</a> 2021
                </div>
            </div>
        </div>
    );
};