import React from "react";
import {cssClass} from "../../../Helper";
import {TableHeaderTh} from "./TableHeaderTh";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const TableHeaderTr = (props) => {
    const data = props.tr.data;

    return (
        <div className={cssClass('table__tr', props.tr.classes || [])}>
            {
                data && data.map(function(th, index) {
                    return <TableHeaderTh th={th || ''}  key={index} index={index} order={props.order} handleSort={props.handleSort}/>
                })
            }
        </div>
    );
};