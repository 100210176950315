import React from "react";
import {Multiple} from "./SearchFilterType/Multiple";

export const SearchFilter = (props) => {
    const filters = [];

    props.filters && Object.values(props.filters).map(function (filter, index) {
        if (filter.type === 'multiple') {
            return filters.push(<Multiple filterParams={props.filterParams} filter={filter} handleFilter={props.handleFilter} key={index}/>);
        }
    })

    return (
        <>
            {filters}
        </>
    );
};